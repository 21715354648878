<template>
  <div class="content">
    <div class="qt-block-ui" v-if="loadPage"></div>
    <PageHeader title="Notificações"/>
    <section class="page-content container-fluid">
     <div class="no-content" v-if="notifications.length == 0 && loadPage == false">
        <span class="material-symbols-outlined block">notifications</span>
        Nenhuma notificação
     </div>
       <div class="row comments-row" v-else>
          <div class="col">
             <ol class="timeline">
                <div>
                  <ul class="list-notify-dates">
                    <li v-for="(notification, i) in notifications" :key="i">
                      <span class="timeline-item-icon filled-icon icon-date-notify">
                        {{i}}
                      </span>
                      <ul>
                        <li class="timeline-item" v-for="(item, j) in notification" :key="j">
                         <span class="timeline-item-icon filled-icon type-info" v-if="item.type == 'INFO'">
                            <span class="material-symbols-outlined">info</span>
                         </span>
                         <span class="timeline-item-icon filled-icon type-success" v-if="item.type == 'SUCCESS'">
                            <span class="material-symbols-outlined">check</span>
                         </span>
                         <span class="timeline-item-icon filled-icon type-text" v-if="item.type == 'TEXT'">
                            <span class="material-symbols-outlined">chat</span>
                         </span>
                         <span class="timeline-item-icon filled-icon type-warning" v-if="item.type == 'WARNING'">
                            <span class="material-symbols-outlined">warning</span>
                         </span>
                         <span class="timeline-item-icon filled-icon type-alert" v-if="item.type == 'ALERT'">
                            <span class="material-symbols-outlined">notifications</span>
                         </span>
                         <div class="timeline-item-wrapper">
                            <div class="timeline-item-description">
                              <span class="commented-at">
                                <a href="#">{{item.title}}</a>
                                <time>{{item.created_at | date('HH:mm')}}</time>
                              </span>
                            </div>
                            <div class="comment">
                               <p>{{item.content}}</p>
                            </div>
                         </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
             </ol>
          </div>
       </div>
    </section>
    <br />
    <pagination :lastPage="pages" @change="list" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import axios from '@/plugins/axios';
import Pagination from '@/components/rebranding/Pagination.vue';
import moment from 'moment';

const webApi = axios();

export default {
  name: 'webhooks',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      notifications: [],
      loadPage: false,
      pages: 0,
      size: 20,
    };
  },
  computed: {
  },
  created() {
    this.list();
    // this.create();
  },
  methods: {
    create() {
      webApi.post('notifications', {
        title: 'Teste',
        type: 'WARNING',
        content: 'Oi, sou uma notificação de teste',
      }).then((response) => {
      }, (error) => {
        this.$toast.show({
          title: 'Erro',
          content: 'Notification error',
          type: 'error',
        });
        this.content = error;
        this.fetchedAction = true;
      });
    },
    humanDate(date) {
      return moment(date).fromNow();
    },
    groupBy(xs, key) {
      return xs.reduce((rv, x) => {
        const dateKey = moment(x[key]).format('DD/MM/YYYY');
        (rv[dateKey] = rv[dateKey] || []).push(x);
        return rv;
      }, {});
    },
    list(page = 0) {
      this.loadPage = true;
      webApi.get(`notifications?page=${page}&size=${this.size}`).then(async (response) => {
        this.loadPage = false;
        this.pages = response.data.totalPages;
        if (response.data.content.length > 0) {
          this.notifications = this.groupBy(response.data.content, 'created_at');
        }
      }, (error) => {
        this.$toast.show({
          title: 'Erro',
          content: 'Notification error',
          type: 'error',
        });
        this.content = error;
        this.loadPage = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.comments-row {
  padding-left: 48px;
}
.comments-row .timeline {
  width: 85%;
  max-width: 700px;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 32px 0 32px 32px;
  border-left: 2px solid var(--c-grey-200);
  font-size: 1.125rem;
}
.comments-row .timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}
.comments-row .timeline-item + * {
    margin-top: 24px;
}
.comments-row .timeline-item-icon.filled-icon {
  color: #fff;
}

.comments-row .timeline-item-icon.filled-icon.type-text {
  background-color: #688afd;
}

.comments-row .timeline-item-icon.filled-icon.type-warning {
  background-color: #f3b033;
}

.comments-row .timeline-item-icon.filled-icon.type-info {
  background-color: #9597d9;
}

.comments-row .timeline-item-icon.filled-icon.type-alert {
  background-color: #f34343;
}

.comments-row .timeline-item-icon.filled-icon.type-success {
  background-color: #32b757;
}

.comments-row .user-name-picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #fff !important;
  border-radius: 50%;
  font-weight: 400;
  font-size: inherit;
  font-style: normal;
}
.comments-row .timeline-item-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: -132px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 0 0 6px #fff;
  box-shadow: 0 0 0 6px #fff;
  pointer-events: none;
}
.comments-row .timeline-item-icon svg {
  width: 20px;
  height: 20px;
}
.comments-row .timeline-item-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 6px;
  gap: 8px;
  color: var(--c-grey-400);
}
.comments-row .user-name-picture.small {
    width: 32px;
    height: 32px;
}
.comments-row .commented-at {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
}
.comments-row .timeline-item-description a {
    color: var(--c-grey-500);
    font-weight: 500;
    text-decoration: none;
}
.comments-row .comment {
    position: relative;
    margin-top: 12px;
    color: var(--c-grey-500);
    border: 1px solid var(--c-grey-200);
    -webkit-box-shadow: 0 4px 4px 0 var(--c-grey-100);
    box-shadow: 0 4px 4px 0 var(--c-grey-100);
    border-radius: 6px;
    padding: 16px;
    font-size: 1rem;
}
.comments-row .comment p {
    margin-bottom: 0;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
button .delete-icon {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    color: var(--gray-font-color-3);
    margin-left: auto;
    font-size: 18px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
}
.icon-date-notify {
  margin-top: 30px !important;
}
.list-notify-dates > li:first-child .icon-date-notify{
  margin-top: -30px !important;
}
.icon-date-notify {
  margin-left: -120px !important;
  margin-bottom: 50px !important;
  border-radius: 5% !important;
  background-color: #752de6 !important;
  height: auto !important;
  width: 110px !important;
  padding: 5px !important;
}
.li::marker {
  display: none !important;
}
.block {
  display: block !important;
}
.no-content {
  font-size: 25px;
  color: #ccc;
  margin-top: 30px;
  text-align: center;
}
.no-content span {
  display: block;
  font-size: 70px;
}
</style>
